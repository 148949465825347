// 公用过滤器
/**
 * 处理直播室和特训班消息数据的工具函数
 * 注：特训班当前消息和历史消息数据结构不一样，请看API文档（历史消息数据没有item.m
 */

export const message = function (list, authUser = null) {
  // 把直播室消息过滤成没有del的消息
  let data = []
  let i
  for (let item of list) {
    switch (item.m) {
      // 当天特训班消息
      case 'ADD':
        // console.log(1)
        // 添加数据
        data.push(item.data)
        break
      case 'UPDATE':
        i = data.map(_ => _.id).indexOf(item.data.id)
        if (item.data.type === 11) {
          // 红包相关信息
          let redpack = JSON.parse(item.data.content)

          if (i !== -1) {
            if (redpack.isEmpty) {
              data.splice(i, 1, item.data)
            } else if (redpack.isReceived && authUser && authUser.id === redpack.userId) {
              data.splice(i, 1, item.data)
            }
          }
        } else {
          // 左侧点赞
          if (i !== -1) {
            data.splice(i, 1, item.data)
          }
        }
        break
      case 'DEL':
        // 删除数据
        i = data.map(_ => _.id).indexOf(item.data.id)
        i !== -1 && data.splice(i, 1)
        break
      // 只有历史特训班消息才经过这里
      case undefined:
        data.push(item)
        break
    }
  }
  return data
}

// 富文本处理图片及表情
export const switchBody = function (text, imgSuffix = '') {
  // 过滤不合法的内容
  if (text === undefined || text === null || text === '') return ''

  // 过滤表情
  if (/\[@.*@\]/.test(text)) {
    text = text.replace(/\[@(F_[0-9]{1,4})([J|G])@\]/g, (s0, s1, s2) => {
      return `<img class="js-ubb" src="${process.env.UBB_IMG_URL}${s1}.${s2 === 'J' ? 'jpg' : 'gif'}" alt="表情" />`
    })
  }

  // 过滤图片
  if (/\[#.*#\]/.test(text)) {
    text = text.replace(/\[#(.+?)#\]/g, (s0, s1) => {
      return `<img class="js-ubb-img-in am-img-thumbnail" src="${s1}${imgSuffix}" origin="${s1}" alt="图片" />`
    }) 
  }

  return text
}

// 文章模块,我的文章(老师)文章类型
export const toType = function (type) {
  switch (type) {
    case 1:
      return '藏金阁'
    case 2:
      return '付费文章'
    case 3:
      return '免费文章'
  }
}

// 网站首页和直播首页用到
export const famousRoomStatus = function (v) {
  switch (v) {
    case 11:
      return '在线'
    case 12:
      return '暂停'
    case 21:
    case undefined:
      return '离线'
  }
}

export const identity = function (v) {
  switch (v) {
    case 0:
      return '用户'
    case 1:
      return '老师'
    case 9:
      return '管理员'
  }
}

// 将手机号码转换成 xxx****xxxx 格式
export const convertPhoneNumber = function (v) {
  if (!v) return
  let phone = /^1[3456789]\d{9}$/
  if (phone.test(v)) {
    // 方法的链式应用只能在数据类型不变的情况下才能用
    let str = v.toString().split('')
    str.splice(3, 4, '****')
    str = str.join('')
    return str
  } else {
    return ''
  }
}

// 下面两个方法用于用户中心页面下观点版块和藏金阁文章版块
export const type = function (v) {
  switch (v) {
    case 1:
      return '藏金阁'
    case 2:
      return '付费文章'
    case 3:
      return '免费文章'
  }
}

export const status = function (v) {
  if (v.type === 1) {
    switch (v.status) {
      case 2:
        return '已退款'
      case 3:
        return '服务中'
      case 4:
        return '已到期'
    }
  } else if (v.type === 2) {
    switch (v.status) {
      case 1:
        return '完成'
      case 2:
        return '已退款'
    }
  }
}

export const level = function (v, flag) {
  let name
  switch (v) {
    case 1:
      name = '幼儿园'
      break
    case 2:
      name = '小学'
      break
    case 3:
      name = '初中'
      break
    case 4:
      name = '高中'
      break
    case 5:
      name = '大学'
      break
    case 6:
      name = '硕士'
      break
    case 7:
      name = '博士'
      break
  }
  return flag ? '等级：' + name : name
}

// 用于特训班和文章购买页
export const levelDiscount = function (v) {
  switch (v) {
    case 3:
      return '9.9'
    case 4:
      return '9.8'
    case 5:
      return '9.7'
    case 6:
      return '9.6'
    case 7:
      return '9.5'
  }
}

export const convertVote = function (v) {
  let val = String(v)
  if (val.length < 3) {
    // return '**' + val
    return '****'
  } else {
    // return '****' + val.slice(-2)
    return '****'
  }
}

// 用于首页和直播首页展示直播老师2018投票身份
export const teacherBedge = function (v) {
  if (!v) return ''
  v = String(v)
  if (v.length > 1) {
    v = v.slice(0, 1)
  }
  let c = ''
  switch (+v) {
    case 1:
      c = 'a'
      break
    case 2:
      c = 'b'
      break
    case 3:
      c = 'c'
      break
    case 4:
      c = 'd'
      break
    case 5:
      c = 'e'
      break
    case 6:
      c = 'g'
      break
  }
  return c
}
