/*
 * 开启又拍云的webp服务，判断浏览器是否支持webp格式图片(图片域名一定要是又拍云)
 */
const Webp = {
  methods: {
    // 在default布局里定义浏览器是否支持webp
    // 因为该框架是服务端渲染，在isSupportWebp函数里定义的话会出现document不存在的错误
    isSupportWebp (imgUrl) {
      return this.$store.state.webp ? imgUrl + '!/format/webp' : imgUrl
    }
  }
}

export default Webp
